import React, { Component } from 'react';
import { Div } from './style'

class Footer extends Component {
  render() {
    return (
      <Div >
        copyright <span dangerouslySetInnerHTML={{__html: '&copy;'}}></span> Frank 2019
      </Div>
    );
  }
}

export default Footer;