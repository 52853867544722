import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css'
import axios from 'axios';

class Tags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: '',
      tagList: '',
    };
    this.getissues = this.getissues.bind(this);
    this.settest = this.settest.bind(this);
  }

  settest(result) {
    const tagList = [];
    const tagHash = {};
    result.map(item => item.labels.map(label => {
      const tag = label.name;
      const tagColor = label.color;
      if (tagHash[tag] === undefined) {
        tagHash[tag] = true;
        const tagTemp = { tag,tagColor, sum: 1 };
        tagList.push(tagTemp);
      } else {
        for (let k = 0; k < tagList.length; k ++) {
          if (tagList[k].tag === tag) {
            tagList[k].sum += 1;
          }
        }
      }
      return tagList;
    }
    ));
    this.setState({ 
      result,
      tagList,
     });

  }

  getissues() {
    axios.get('https://api.github.com/repos/ForFrank/Notes/issues')
    .then( res => res.data)
    .then(result => this.settest(result))
    .catch(e => e)
  }

  componentDidMount() {
    this.getissues();
  }

  render() {
    const { result, tagList } =this.state;
    if (!result) { return null; }
    return (
      
      <div className="tags">
        <div className="tags-top">标签</div>
        <div className="tags-flex">
          {tagList.map( tag =>  
            <Link to={`/tags/${tag.tag}`} key={tag.tag}>
              <p className="tags-list"  style={{background:`#${tag.tagColor}`}}>
                <span>{tag.tag}</span>
                <span>{tag.sum}</span>
              </p>
           </Link>
          )}
        </div>
        
      </div>
    );
  }
}

export default Tags;