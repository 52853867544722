import React, { Component } from 'react';
import './style.css'
import axios from 'axios';
import marked from 'marked';
import hljs from 'highlight.js';


marked.setOptions({
  highlight: code => hljs.highlightAuto(code).value,
});
class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: '',
    };
    this.getissues = this.getissues.bind(this);
    this.settest = this.settest.bind(this);
  }
  settest(result) {
    this.setState({ result })
  }

  getissues() {
    axios.get(`https://api.github.com/repos/ForFrank/Notes/issues/${this.props.match.params.number}`)
    .then((res) => {
      const result = res.data;
      return result;
    }).then(result => this.settest(result))
    .catch(e => e)
  } 

  componentDidMount() {
    this.getissues();
  }
  render() {
    const { result } =this.state;
    if (!result) { return null; }
    return (
      <div className="article">
        <div className="article-layout">
          <div className="article-title">
            {result.title}
          </div>
          <div className="article-time">
            <i className="iconfont icon-date"></i>
            {result.created_at.split('T')[0]}
          </div>
          <div className="article-detail" dangerouslySetInnerHTML={{__html: marked(result.body) }}>
        </div>
        </div>
      </div>
      
    );
  }
}

export default Detail;