import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import Sidebar  from './common/sidebar';
import Footer  from './common/footer';
import Home  from './pages/home';
import Detail  from './pages/detail';
import Posts  from './pages/posts';
import Tags  from './pages/tags';
import Tag  from './pages/tags/tag';
import About  from './pages/about';

class App extends Component {
  render() {
    return (
        <HashRouter>
          <div>
            <Sidebar />
            <Route path='/' exact component={Home}></Route>
            <Route path='/detail/:number' exact component={Detail}></Route>
            <Route path='/posts' exact component={Posts}></Route>
            <Route path='/tags' exact component={Tags}></Route>
            <Route path='/tags/:tag' exact component={Tag}></Route>
            <Route path='/about' exact component={About}></Route>
            <Footer />
          </div>
        </HashRouter>

      
    );
  }
}

export default App;
