import React, { Component } from 'react';
import './style.css'

class About extends Component {
  render() {
    return (
      <div className="about">
        <div className="about-me">About Me</div>
        <div className="about-article">
          <p>研究生在读，希望将来成为一名前端工程师。</p>
          <p>Pursue beauty, Approach beauty, Achieve beauty。</p>
          </div>
      </div>
    );
  }
}

export default About;