import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css'
import axios from 'axios';

class Tag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: '',
      tagResult: '',
      tag: '',
    };
    this.getissues = this.getissues.bind(this);
    this.settest = this.settest.bind(this);
  }

  settest(result) {
    const tag = this.props.match.params.tag;
    const tagResult = result.filter(item =>  {
        for(const i=0; i<item.labels.length; i++) {
            return tag === item.labels[i].name;
        }
    });
    console.log(tagResult);
    this.setState({ 
      result,
      tagResult,
      tag,
     });

  }

  getissues() {
    axios.get('https://api.github.com/repos/ForFrank/Notes/issues')
    .then( res => res.data)
    .then(result => this.settest(result))
    .catch(e => e)
  }

  componentDidMount() {
    this.getissues();
  }

  render() {
    const { result, tagResult, tag } =this.state;
    if (!result) { return null; }
    return (
       <div className="tag">
        <div className="tag-top">{tag}</div>
        {tagResult.map( item => 
        <div className="posts-list" key={ item.number }>
          <Link to={`/detail/${item.number}`}>
            <div className="posts-title">
              <span className="posts-time"> {item.created_at.split('T')[0]}</span>
              <span className="posts-arrow" dangerouslySetInnerHTML={{__html: ' &gt&gt; '}}></span>
                {item.title}
            </div>
          </Link>
        </div>)}
      </div>
    );
  }
}




export default Tag;