import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.css'

class Sidebar extends Component {
  render() {
    return (
      <div className="side">
        <div className="top">
          <div className="logo">
            <img src={require("../../statics/images/logo.png")} alt="forfrank"/>  
          </div>
          <div className="name">Frank</div>
          <div className="text">The road to learn react!</div>
        </div>
        <div className="nav">
            <ul>
              <Link to='/'>
                <li>HOME</li>
              </Link>
              <Link to='/posts'>
                <li>POSTS</li>
              </Link>
              <Link to='/tags'>
                <li>TAGS</li>
              </Link>
              <Link to='/about'>
                <li>ABOUT</li>
              </Link>
            </ul>
        </div>
        <div className="icons">
          <ul>
            <li><i className="iconfont icon-github"></i></li>
            <li><i className="iconfont icon-zhihu0"></i></li>
            <li><i className="iconfont icon-email"></i></li>
          </ul>
        
        </div>
      </div>
    );
  }
}

export default Sidebar;