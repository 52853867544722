import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css'
import axios from 'axios';

class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: '',
    };
    this.getissues = this.getissues.bind(this);
    this.settest = this.settest.bind(this);
  }
  settest(result) {
    this.setState({ result: result })
  }

  getissues() {
    axios.get('https://api.github.com/repos/ForFrank/Notes/issues')
    .then( res => res.data)
    .then(result => this.settest(result))
    .catch(e => e)
  } 

  componentDidMount() {
    this.getissues();
  }
  render() {
    const { result } =this.state;
    console.log(result);
    if (!result) { return null; }
    return (
      
      <div className="posts">
        <div className="posts-top">文章</div>
        {result.map( item => 
        <div className="posts-list" key={ item.number }>
          <Link to={`/detail/${item.number}`}>
            <div className="posts-title">
              <span className="posts-time"> {item.created_at.split('T')[0]}</span>
              <span className="posts-arrow" dangerouslySetInnerHTML={{__html: ' &gt&gt; '}}></span>
                {item.title}
            </div>
          </Link>
        </div>)}
      </div>
    );
  }
}




export default Posts;