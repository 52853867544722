import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css'
import axios from 'axios';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: '',
    };
    this.getissues = this.getissues.bind(this);
    this.settest = this.settest.bind(this);
  }

  settest(result) {
    this.setState({ result: result })
  }

  getissues() {
    axios.get('https://api.github.com/repos/ForFrank/Notes/issues')
    .then( res => res.data)
    .then(result => this.settest(result))
    .catch(e => e)
  } 

  componentDidMount() {
    this.getissues();
    console.log( parseInt(window.location) );
  }
  render() {
    const { result } =this.state;
    if (!result) { return null; }
    return (
      
      <div className="home">
        {result.map( item => 
        <div className="home-list"key={ item.number }>
          <Link to={`/detail/${item.number}`}>
            <div className="home-title">{item.title}</div>
          </Link>
          <div className="home-body">
            {item.body.replace(/[\-\|\(\)\#\%\*\?]/g, '').substr(0, 120)}......
          </div>
          <div className="home-time"> 
            <i className="iconfont icon-date"></i>
            {item.created_at.split('T')[0]}
          <Link to={`/detail/${item.number}`}>
            <span> 阅读全文 </span>
          </Link>
          </div>
        </div>)}
      </div>
    );
  }
}




export default Home;